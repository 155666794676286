import {graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import SogodyText from './SogodyText';
export default function() {
    const data = useStaticQuery(query);
    const {allSanityCompanyTeam:{
        nodes:sgd
    }} = data

    return (
        <div className="sogody-staff">
            <Container> 
                <SogodyText/>
                <Row>
                    {sgd.map((sgd)=>{
                        return <>
                        <Col xs={6} md={4} lg={3} className="">
                            <Card className="employee-card">
                                <Card.Body>
                                    {/* <Image className="employee-img" fluid={sgd.image.fluid} alt="Cta img"></Image> */}
                                    <img style={{  width:"100%", height:"auto",  maxWidth: "327px",maxHeight: "290px",objectFit:"cover"}} src={sgd.image.asset.url} alt="Staf img"/>    
                                    <Card.Text className="card-text">
                                            <p className="name">{sgd.employeeName}</p>
                                            <p className="title">{sgd.employeeTitle}</p>
                                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        </>
                    })}
                </Row>
            </Container>
        </div>
    )
}

export const query = graphql`
{
    allSanityCompanyTeam(sort: {fields: _updatedAt}) {
        nodes {
          employeeTitle
          employeeName
          image {
            asset {
              url
            }
          }
        }
      }
}
`
