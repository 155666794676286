import React,{useEffect,useRef} from 'react'
import CompanyStaff from '../components/CompanyStaff'
import ClientsSecond from '../components/ClientsSecond'
import Layout from "../components/Layout"
import {Container } from 'react-bootstrap'
import CompanyBoxes from '../components/CompanyBoxes'
import CareerCarousel from '../components/CareerCarousel';
import ReactPlayer from 'react-player'
// import myVideo from 'https://assets.sogody.co.uk/sogody/showcase-video.mp4'
import { Seo } from '../components/Seo'
import BannerCompany from '../components/BannerCompany'
import { useLocation } from "@reach/router"
import BookMeetHompage from '../components/BookMeetHomePage'

import thumbnail from "../assets/images/company-video-thumbnail.png"

export default function() {
    // const [videoLoaded, setVideoLoaded] = useState(false);
    const pathLocation = useLocation();
    const url = pathLocation.href;
    const playerRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            playerRef.current.handleClickPreview();
        },100)
    },[])


    return (
    <Layout>  
        <Seo 
            title="About us / Who we are | Company | Sogody" 
            description="Our unwavering focus on high-quality services is at the heart of our company culture and work ethic. Meet our team!"
            url={url}
        />
        <div className="company"> 
        <BannerCompany/>
            <Container>
                {/* { !videoLoaded &&  <img zstyle={{maxHeight: "540px", maxWidth:"100%"}}  src={'https://assets.sogody.co.uk/sogody/company-poster.jpg'} alt="Company"  />  }  */}
                <ReactPlayer
                    ref={playerRef}
                    className='react-player'
                    url= 'https://assets.sogody.co.uk/sogody/showcase-video.mp4'
                    light={thumbnail}
                    width='100%'
                    height='100%'
                    playing={true}
                    volume={1}
                    muted={true}
                    controls={true}
                    loop={false}
                    
                />
            </Container>
            <CompanyBoxes/>
            <CompanyStaff/>  
            <Container>  
            <BookMeetHompage /> 
            </Container>
            <CareerCarousel/>  
            <ClientsSecond/> 
        </div>
    </Layout>
    )
}


