import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import {PortableText} from '@portabletext/react'

export default function() {
    const data = useStaticQuery(query);
    const {allSanitySogodyIntro:{
        nodes:si
    }} = data;

    const myPortableTextComponents = {
        block: {
          normal: ({children}) => <p className="title">{children}</p>,
          h2: ({children}) => <h2 className="heading2">{children}</h2>,
        }
    }

    return (
        <div className="sogody-text">  
                {si.map((si)=>{
                    return  <PortableText value={si._rawTitle} components={myPortableTextComponents}/>
                })}
        </div>
    )
}

export const query = graphql`
{
    allSanitySogodyIntro {
        nodes {
        _rawTitle
        }
    }
}
`

