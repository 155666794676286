import React from 'react'
import { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { Col, Container, Row, Button, Modal } from 'react-bootstrap'
import InitKoalendar from './init-koalendar';

export default function() {

    const data = useStaticQuery(query)
    const {allSanityBookMeetHomepage:{
        nodes:bookMeet
    }} = data

    return (
      <Container>
        <div className="cta-meet">
            <div className="cta-meet-container"> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="cta-text">
                        <h1 className="cta-title">{bookMeet[0].title}</h1>
                        <p className="cta-description">{bookMeet[0].description}</p>
                        <InitKoalendar className="primary-btn-s meet-sogody" buttonText={bookMeet[0].aText} icon={bookMeet[0].logo.asset.url}></InitKoalendar>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="cta-img">
                        <img src={bookMeet[0].image.asset.url} alt="grow manager" />
                    </Col>
                </Row>
            </div>
        </div>
      </Container>
    )
}

export const query = graphql`
{
    allSanityBookMeetHomepage {
      nodes {
        title
        description
        aHref
        aText
        image {
          asset {
            url
          }
        }
        logo {
          asset {
            url
          }
        }
      }
    }
  }
`