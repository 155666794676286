import React from 'react'
import '../assets/style.css';
import { Row, Container, Col} from 'react-bootstrap';
import SogodyHiring from "../components/SogodyHiring"
import SogodyBox from "../components/SogodyBox";

export default function() {

    return (
        <div className="company-boxes">  
            <Container> 
                <Row>
                    <Col lg="8">
                        <SogodyBox/>
                    </Col>
                    <Col lg="4" className="hiring-b">
                            <SogodyHiring/>
                    </Col> 
                </Row>
            </Container>
        </div>
    )
}



