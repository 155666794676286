import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Button, Figure } from "react-bootstrap";
//import Image from "gatsby-image"


const ComponentName = () => {
    
    const data = useStaticQuery(query);
    const {allSanitySogodyHiring:{
        nodes:sb
    }} = data

    const today = new Date();
    const careers = data.allSanityCareers.nodes;
    const total= careers.filter(careers=> new Date(careers.date) >= today || careers.hideDate).length;

return <div className="sogody-hiring-box">
            {sb.map((sb)=>{
                return(
                <div className="hiring-content">
                    {/* <Row>
                        <Col xs="12" md="12" lg="12" className="hiring-content"> */}
                            <p className="title">{sb.title}</p>
                            <p className="subtitle">There {total == 0 ? "are" :  (total > 1 ? "are" : "is" ) }  <span className="jobs">{total}</span> open job {total == 0 ? "positions" :  (total>1 ? "positions" : "position")} at Sogody</p>
                            <div  className="sog-box-img">
                                <img src={sb.image.asset.url} style={{ maxWidth: "100%", maxHeight: "100%" }} className="img-fluid" alt="text"/>
                            </div>
                            <div className="hiring-btn">
                            <Button href="/careers/" className="tertiary-btn-s">{sb.btnText} 
                                <Figure className="read-more">
                                    <Figure.Image   src={require('../assets/images/next_service.png').default}  />
                                </Figure>
                            </Button>
                            </div>
                        {/* </Col> 
                    </Row> */}
                </div>
                )})}
</div>
}

export const query = graphql`
{
    allSanityCareers {
        nodes {
          slug
          date
          hideDate
        }
        totalCount
    }
    allSanitySogodyHiring {
        nodes {
          title
          btnText
          image {
            asset {
              url
            }
          }
        }
      }
}
`

export default ComponentName

