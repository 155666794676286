import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {BLOCKS, MARKS} from "@contentful/rich-text-types";
import {PortableText} from '@portabletext/react'
import { Row, Col} from "react-bootstrap";

const ComponentName = () => {
    
    const data = useStaticQuery(query);
    const {allSanitySogodyBox:{
        nodes:sb
    }} = data

    const myPortableTextComponents = {
        block: {
          normal: ({children}) => <p className="sb-paragraph">{children}</p>,
          h2: ({children}) => <h2 className="heading2">{children}</h2>,
        },

        list: {
            // Ex. 1: customizing common list types
            bullet: ({children}) => { return <ul className="ul-list">{children}</ul>},
            number: ({children}) => <ol className="ol-list">{children}</ol>,
        
            // Ex. 2: rendering custom lists
            checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
          },

          listItem: {
            // Ex. 1: customizing common list types
            bullet: ({children,value:{style}}) => <li>{style == "normal" ? <p className='sb-paragraph'>{children}</p> : children}</li>,
            bullet: ({children,value:{style}}) => <li>{style == "normal" ? <p className='sb-paragraph'>{children}</p> : children}</li>,
        
            // Ex. 2: rendering custom list items
          },
      
        marks: {
          link: ({children, value}) => {
            const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
            return (
              <a href={value.href} rel={rel}>
                {children}
              </a>
            )
          },
        },
    }

return <div className="sogody-box">
    {sb.map((sb)=>{ 
        return(
        <div className="box-content">
            <Row>
                <Col xs="12" md="6" lg="5" style={{margin:"auto"}}>
                    <h2 className="title">{sb.title}</h2>
                    <div className="sogody-box-text">
                        {/* TODO */}
                        <PortableText
                            value={sb._rawContent}
                            components={myPortableTextComponents}
                        />
                    </div> 
                </Col>
                <Col xs="12" md="6" lg="7" >
                    <div style={{height:"100%"}} className="sog-box-img">
                        <img src={sb.image.asset.url}  alt="Sogody box" style={{ maxWidth: "100%", maxHeight: "100%", minHeight: "100%", objectFit: "cover"  }}/>
                    </div>
            
                    {/* <Image className="" fluid={sb.image.fluid} alt="Cta img"></Image> */}
                </Col>
            </Row>
        </div>
        )})}
</div>
}

export const query = graphql`
{
    allSanitySogodyBox {
        nodes {
        title
        image {
            asset {
             url
            }
        }
        _rawContent
    }
    }
}
`

export default ComponentName

